<template>
  <div class="daxxiao">
    <div class="mask-box" id="download-box" v-if="info" @click="xiaoshi()"  >
        <div class="master">
              <p>请打开手机浏览器输入网址</p>
              <p @click="copy1"   style="color: red;">{{ cope }}</p>
        </div>
    </div>
    <div class="box">
      <div class="header" ref="header">
        <img src="../assets/download/header.png" alt="" />
        <img src="../assets/download/logo.png" alt="" class="logo" />
      </div>
      <div class="navigation" ref="navigation">
        <ul>
          <li
            :class="aktiveindex == 1 ? 'aktive' : ''"
            @click="aktiveindex = 1"
          >
            品牌故事
          </li>
          <li
            :class="aktiveindex == 5 ? 'aktive' : ''"
            @click="aktiveindex = 5"
          >
            动态
          </li>
          <li
            :class="aktiveindex == 2 ? 'aktive' : ''"
            @click="aktiveindexdata"
          >
            领养
          </li>
          <li
            :class="aktiveindex == 3 ? 'aktive' : ''"
            @click="aktiveindex = 3"
          >
            喂养
          </li>
          <li
            :class="aktiveindex == 4 ? 'aktive' : ''"
            @click="aktiveindex = 4"
          >
            屠宰
          </li>
        </ul>
      </div>
      <!-- <div style="height: 83px;"></div> -->
      <div class="navigation fled" v-if="scrollshow">
        <ul>
          <li
            :class="aktiveindex == 1 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 1))"
          >
            品牌故事
          </li>
          <!-- <li
            :class="aktiveindex == 5 ? 'aktive' : ''"
            @click="aktiveindex = 5"
          >
            动态
          </li> -->
          <li
            :class="aktiveindex == 2 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 2))"
          >
            领养
          </li>
          <li
            :class="aktiveindex == 3 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 3))"
          >
            喂养
          </li>
          <li
            :class="aktiveindex == 4 ? 'aktive' : ''"
            @click="Jumpindex((Jumplenght = 4))"
          >
            屠宰
          </li>
        </ul>
      </div>
      <div class="buttomcolor ppgsbuttom" v-if="aktiveindex == 1">
        <!-- 品牌故事 -->
        <!-- 视频 -->
        <div class="ppgs-viope-box">
          <video-player
            ref="myVideo"
            :playsinline="true"
            :options="playerOptions"
          >
          </video-player>
        </div>
        <!-- 标题 -->
        <div class="pig-table">如何才能让大山的土猪走进城市家庭的餐桌呢？</div>
        <!-- <div class="fangxinchi">
        <img src="../assets/download/fangxinc.png" alt="" />
      </div> -->
        <div class="fangxinchi-box">
          <div class="duizhong">
            <div class="left">
              <img src="../assets/download/shouqi.png" alt="" />
            </div>
            <div class="text">
              禾乡农场是一个全新的“生态云养猪”模式，虚拟现实相结合，消费者领养的猪仔寄养于高山农户家，24小时一对一、360度可视化直播、自由喂养、全程视频记录可溯，养猪保险为特别意外提供全额赔付！为振兴乡村帮扶于农户，为健康品质生活服务于用户，是我们的核心宗旨！！！
            </div>
          </div>
        </div>
        <!-- <div class="linian-box">
        <img src="../assets/download/linian.png" alt="" />
      </div> -->
        <div class="ppgs-buttom-box">
          <div class="one">
            <div class="img-box-left">
              <img src="../assets/download/zurou.png" alt="" />
            </div>
            <div class="text-box-right">
              <div class="title">
                享受健康的猪肉
              </div>
              <div class="text">
                小猪从领养的第一天开始，所吃的每一口食物，都由您亲自把关，有山上的野草和野菜，还有各种纯天然的粮食和山泉水。
              </div>
            </div>
          </div>
          <div class="two">
            <div class="img-box-left">
              <div class="title">
                体验养猪的乐趣
              </div>
              <div class="text">
                通过线上娱乐的方式，喂养属于自己的小猪，线下远程同步执行所有喂养行为。
              </div>
            </div>
            <div class="text-box-right">
              <img src="../assets/download/xinxqu.png" alt="" />
            </div>
          </div>
          <div class="one">
            <div class="img-box-left">
              <img src="../assets/download/lanren.png" alt="" />
            </div>
            <div class="text-box-right">
              <div class="title-lit">
                帮助贫困山区
              </div>
              <div class="title">
                空巢老人
              </div>
              <div class="text">
                您的每次喂养，都能使得山区老人将现有资源(粮食、瓜果蔬菜、天然猪草等)快速变现。
              </div>
            </div>
          </div>
        </div>
        <!-- 点击图片 -->
        <div class="dianji-img-box">
          <img src="../assets/download/1.png" alt="" v-show="imgindex == 1" />
          <img src="../assets/download/2.png" alt="" v-show="imgindex == 2" />
          <img src="../assets/download/3.png" alt="" v-show="imgindex == 3" />
          <img src="../assets/download/4.png" alt="" v-show="imgindex == 4" />
          <img src="../assets/download/5.png" alt="" v-show="imgindex == 5" />
          <img src="../assets/download/6.png" alt="" v-show="imgindex == 6" />
        </div>
        <div class="dianji-img-six">
          <img
            src="../assets/download/zu1.png"
            alt=""
            :class="imgindex == 1 ? 'aktive' : ''"
            @click="imgindex = 1"
          />
          <img
            src="../assets/download/zu2.png"
            alt=""
            :class="imgindex == 2 ? 'aktive' : ''"
            @click="imgindex = 2"
          />
          <img
            src="../assets/download/zu3.png"
            alt=""
            :class="imgindex == 3 ? 'aktive' : ''"
            @click="imgindex = 3"
          />
          <img
            src="../assets/download/zu4.png"
            alt=""
            :class="imgindex == 4 ? 'aktive' : ''"
            @click="imgindex = 4"
          />
          <img
            src="../assets/download/zu5.png"
            alt=""
            :class="imgindex == 5 ? 'aktive' : ''"
            @click="imgindex = 5"
          />
          <img
            src="../assets/download/zu6.png"
            alt=""
            :class="imgindex == 6 ? 'aktive' : ''"
            @click="imgindex = 6"
          />
        </div>
        <!-- 底部 -->
        <!-- <div class="ppgsfooter">
          <div>禾乡农场（武汉觅网科技有限公司）成立于2017年,</div>
          <div>公司自成立以来，十多年的发展历程，始终坚持创新</div>
          <div>品质、服务、节约、敬业、感恩、12字理念。</div>
          <div>禾乡农场致力于科技、绿色、健康、品质、助农为目标</div>
        </div> -->
      </div>
      <div class="buttomcolor lybuttom" v-if="aktiveindex == 2">
        <!-- 领养小猪 -->
        <div class="linyang" style="display: flex;align-items: center">
            <div style="padding: 32px 0 0 20px ;" >
                <div v-for="value in piglist" :key="value.id" class="canva">
                  <p style="font-size: 18px;font-weight: 600;line-height: 35px;">{{ value.pig_type }}:</p>
                  <p style="margin-left: 10px;font-size: 18px;font-weight: 600;line-height: 35px;">{{ value.price }}元</p>
                </div>
            </div>
          </div>
        <div class="lingyang">
          <img src="../assets/download/linyangtitle.png" alt="" />
        </div>
        <!-- 领养 -->
        <div class="swipc-box-3d">
          <carousel-3d :animationSpeed="500" :controls-visible="true">
            <slide
              v-for="(photo, i) in photos"
              :index="i"
              :key="i"
              class="slide-box"
            >
              <template
                slot-scope="{ index, isCurrent, leftIndex, rightIndex }"
              >
                <img
                  class="spin_img"
                  :src="photo.url"
                  :data-index="index"
                  :class="{
                    current: isCurrent,
                    onLeft: leftIndex >= 0,
                    onRight: rightIndex >= 0
                  }"
                />
              </template>
            </slide>
          </carousel-3d>
        </div>

        <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item>1</van-swipe-item>
        <van-swipe-item>2</van-swipe-item>
        <van-swipe-item>3</van-swipe-item>
        <van-swipe-item>4</van-swipe-item>
      </van-swipe> -->
        <!-- <div class="ly-list">
        <img src="../assets/download/pigshenzhang.png" alt="" />
      </div> -->
        <div class="ly-list-box frame">
          <div class="title">
            <span>你的猪在这里生长</span>
            <img src="../assets/download/shou.png" alt="" />
          </div>
          <div class="img-box">
            <img src="../assets/download/tu-1.png" alt="" class="tu-1" />
            <img src="../assets/download/tu-2.png" alt="" class="tu-2" />
          </div>
          <div class="img-box">
            <img src="../assets/download/tu-3.png" alt="" class="tu-3" />
            <img src="../assets/download/tu-4.png" alt="" class="tu-4" />
            <img src="../assets/download/tu-5.png" alt="" class="tu-5" />
          </div>
          <div class="text-box">
            <span
              >土猪生活在海拔1000~1500米的山村，村内植被丰茂、流水潺潺，负氧离子自然产生，空气清新，风光秀美，非常适合土猪的生长，我们的土猪将生活在这样一个无污染，无破坏的天然环境。
            </span>
            <img src="../assets/download/tu-6.png" alt="" class="tu-6" />
          </div>
        </div>
        <!-- 领养流程 -->
        <div class="lingyang lingyang-m-b">
          <img src="../assets/download/lingyangliuc.png" alt="" />
        </div>
        <div class="lc-list lc-m-b">
          <img src="../assets/download/Step1.png" alt="" />
          <img src="../assets/download/Step2.png" alt="" />
          <img src="../assets/download/Step3.png" alt="" />
        </div>
        <div class="lc-list lc-m-b">
          <img src="../assets/download/Step4.png" alt="" />
          <img src="../assets/download/Step5.png" alt="" />
          <img src="../assets/download/Step6.png" alt="" />
        </div>
        <div class="ly-viope-box">
          <div class="videos">
            <video-player
              ref="myVideo"
              :playsinline="true"
              :options="playerOptionsTwo"
            >
            </video-player>
          </div>
        </div>
      </div>
      <div class="buttomcolor wybuttom" v-if="aktiveindex == 3">
        <!-- 喂养 -->
        <div class="weiyang">
            <div style="padding: 50px 0 0 20px ;" >
                <div class="canvas">
                  <div  v-for="value in piglist" :key="value.id">
                    <p style="font-size: 20px;font-weight: 600;line-height: 30px;color: #3ABA71;">{{value.start_days}}-{{value.end_days}}天</p>
                  <p style="font-size: 18px;font-weight: 600;line-height: 40px;">{{value.name}}：{{value.buy_price}}元/顿</p>
                  </div>
                </div>
            </div>
          </div>
        <div class="wy-viope-box">
          <div class="videos">
            <video-player
              ref="myVideo"
              :playsinline="true"
              :options="playerOptionsthree"
            >
            </video-player>
          </div>
        </div>
        <!-- 轮播图 -->
        <div class="wy-swipe-box">
          <div class="text-box" v-show="lenght == 0">
            来自高山的玉米,富含维生素A、E、谷氨酸、膳食纤维等营养素以及多种微量元素。玉米作为土猪的常用饲料，不仅能给土猪提供能量，还能使土猪肉的肉质更加紧实，肥瘦相兼，风味绝佳。
          </div>
          <div class="text-box" v-show="lenght == 1">
            山上的枸杞为天然野生无污染的药材，没有农药污染，是真正的纯天然绿色食品、人吃有养肝明目，补血安神，润肺止咳等功效，猪食之可增加肌肉间脂肪而减少皮下脂肪，提高猪肉持水力，使肉质鲜美。
          </div>
          <div class="text-box" v-show="lenght == 2">
            这里给土猪喂养食谱都是由专业人员根据猪的每一个成长阶段制定的。食用的都是来自山里的玉米、猪草、山泉水、以及五谷杂粮之类的有机健康食物，杜绝任何饲料，让其逐渐变胖并维持，这样的猪肉才是真正的土猪肉。
          </div>
          <!-- <div class="text-box" v-show="lenght == 3">
          来自高山的玉米,富含维生素A、E、谷氨酸、膳食纤维等营养素以及多种微量元素。玉米作为土猪的常用饲料，不仅能给土猪提供能量，还能使土猪肉的肉质更加紧实，肥瘦相兼，风味绝佳。
        </div> -->
          <div class="swipe-box">
            <van-swipe
              ref="swiper"
              class="my-swipe"
              :autoplay="5000"
              indicator-color="white"
              @change="onChange"
            >
              <van-swipe-item v-for="(item, index) in swipeimg" :key="index">
                <!-- <img src="../assets/download/taochan.png" alt="" /> -->
                <img :src="item" alt="" />
              </van-swipe-item>
              <!-- <van-swipe-item>
              <img src="../assets/download/gouqi.png" alt="" />
            </van-swipe-item>
            <van-swipe-item>
              <img src="../assets/download/taochan.png" alt="" />
            </van-swipe-item> -->
              <!-- <van-swipe-item>
              <img src="../assets/download/gouqi.png" alt="" />
            </van-swipe-item> -->
              <div class="custom-indicator" slot="indicator">
                <div :class="lenght == 0 ? 'lu' : 'bai'"></div>
                <div :class="lenght == 1 ? 'lu' : 'bai'"></div>
                <div :class="lenght == 2 ? 'lu' : 'bai'"></div>

                <!-- {{ lenght + 1 }}/3 -->
              </div>
            </van-swipe>
          </div>
          <div class="zuo" data-index="0" @click="onChangeprev">
            <img src="../assets/download/sahnglu.png" alt="" />
          </div>
          <div class="you" data-index="2" @click="onChangenaxt">
            <img src="../assets/download/xialu.png" alt="" />
          </div>
        </div>
        <!-- 团队 -->
        <div class="tuandui">
          <img src="../assets/download/tuandui.png" alt="" />
        </div>
        <!-- 团队列表 -->
        <!-- <div class="tuandui-list">
        <img src="../assets/download/listone.png" alt="" />
      </div>
      <div class="tuandui-list">
        <img src="../assets/download/listtwo.png" alt="" />`  
      </div>
      <div class="tuandui-list list-m-b">
        <img src="../assets/download/listshore.png" alt="" />
      </div> -->
        <div class="tuandui-list-box frame">
          <img src="../assets/download/weya1.png" alt="" />
          <div class="right">
            <div class="title">专业的地勤服务</div>
            <div class="text">我们的地勤专家，</div>
            <div class="text">正在进行规范化的培训</div>
          </div>
        </div>
        <div class="tuandui-list-box frame">
          <img src="../assets/download/weya2.png" alt="" />
          <div class="right">
            <div class="title">经验丰富的养殖农户</div>
            <div class="text">饲养农户平均养殖</div>
            <div class="text">经验20年以上</div>
          </div>
        </div>
        <!-- <div class="tuandui-list-box">
        <img src="../assets/download/weya3.png" alt="" />
        <div class="right">
          <div class="title-1">喂养方式</div>

          <div class="text-1">
            自由喂养：用户可选择自己搭配食物种类或者使用套餐喂养猪崽；
          </div>
          <div class="text-1">
            托管喂养：用户选择托管时间和套餐，由平台根据您的选择进行定时投喂；
          </div>
          <div class="text-1">
            好友一起喂养：和好友一起合养猪崽，好友可以帮你喂养。
          </div>
        </div>
      </div> -->
        <div class="tuandui-list list-m-b">
          <img src="../assets/download/listshore.png" alt="" />
        </div>
        <!-- 后顾之忧 -->
        <div class="tuandui">
          <img src="../assets/download/houguzhiyou.png" alt="" />
        </div>
        <!-- <div class="hougu-list">
        <img src="../assets/download/hougu.png" alt="" />
      </div> -->
        <div class="hougu-list-box frame">
          <div class="weiya-box">
            <img src="../assets/download/weya4.png" alt="" class="img-1" />
            <div class="right">
              <div class="title">保险保障</div>
              <div class="text">
                每头小猪有一份养殖险，成长过程中，如不幸发生了死亡的意外，保险全额补偿！
              </div>
            </div>
          </div>
          <div class="weiya-box">
            <div class="left">
              <div class="title">意外保障</div>
              <div class="text">因农户看管不利所造成猪只丢失问题由平台理赔</div>
            </div>
            <img src="../assets/download/weya5.png" alt="" class="img-2" />
          </div>
        </div>
      </div>
      <div class="buttomcolor tzbuttom" v-if="aktiveindex == 4">
        <div class="tuzai" style="display: flex;justify-content: center;align-items: center">
                  <div>
                    <p style="font-size: 15px;font-weight: 600;line-height: 30px;margin-top: 30px;">出栏费(农户到屠宰场费用): {{driver}}元</p>
                    <p style="font-size: 15px;font-weight: 600;line-height: 30px;">屠宰费(检疫+屠宰+分割+真空包装): {{butcher}}元</p>
                    <p style="font-size: 15px;font-weight: 600;line-height: 30px;">京东物流(保鲜袋+泡沫箱+包装+物流费): {{logistics}}元</p>
                  </div>
          </div>
        <!-- 屠宰 -->
        <!-- <div class="slaughter-top">
        <img src="../assets/download/tz.png" alt="" />
      </div> -->
        <div class="slaughter-top-box frame">
          <div class="title">公开透明 放心溯源</div>
          <div class="text">
            配送到家的猪肉重新拼凑都能拼成完整的猪，且每块猪肉都有独一无二的编码，可以看见猪仔的整个成长历程视频和喂养数据。
          </div>
          <div class="fd-img">
            <img src="../assets/download/rou.png" alt="" />
            <img src="../assets/download/sahnma.png" alt="" />
          </div>
          <div class="tecenter">
            <img src="../assets/download/kandanan.png" alt="" />
          </div>
        </div>
        <div class="slaughter-title">
          <img src="../assets/download/tuzaititlr.png" alt="" />
        </div>
        <!-- <div class="slaughter-buttom">
        <img src="../assets/download/tzliuc.png" alt="" />
      </div> -->
        <div class="slaughter-buttom-box frame">
          <div class="one">
            <img src="../assets/download/tz1.png" alt="" class="tupian-1" />
            <div class="teleft text-1">
              <div class="title">预约屠宰</div>
              <div class="text">
                饲养8个月左右，便可在线上预约屠宰。此时土猪有了充分的时间来沉淀脂肪，这时猪肉的风味物质丰富，口感韧劲。
              </div>
            </div>
          </div>
          <div class="one">
            <div class="teleft text-2">
              <div class="title">严格检疫</div>
              <div class="text">
                待宰生猪宰前检疫的意义是防止肉品污染和疫病的传播,杜绝病害猪及其肉制品流入市场，进入餐桌。
              </div>
            </div>
            <img src="../assets/download/tz2.png" alt="" class="tupian-2" />
          </div>
          <div class="one">
            <img src="../assets/download/tz3.png" alt="" class="tupian-1" />
            <div class="teleft text-1">
              <div class="title-mb">宰杀过程</div>
              <div class="text">
                每头猪在检疫过后，敲上专属钢印便于跟踪观察；宰杀过程由专业人员全程拍摄，用户可选择亲临现场观看或者线上观看直播两种方式。
              </div>
            </div>
          </div>
          <div class="one">
            <div class="teleft text-2">
              <div class="title">冷库排酸</div>
              <div class="text">
                由于肉类屠宰后会出现肉类乳酸，影响口感，需要通过降低温度，使乳酸慢慢分解成二氧化碳、水、酒精等物质然后挥发。
              </div>
            </div>
            <img src="../assets/download/tz4.png" alt="" class="tupian-2" />
          </div>
          <div class="one">
            <img src="../assets/download/tz5.png" alt="" class="tupian-1" />
            <div class="teleft text-1">
              <div class="title">编码盖章</div>
              <div class="text">
                分割前期，猪肉分割师根据猪肉结构对每个分割部位编码盖章。用户可依据编码，对每一块猪肉追踪溯源。
              </div>
            </div>
          </div>
          <div class="one">
            <div class="teleft text-2">
              <div class="title">整猪分割</div>
              <div class="text">
                猪肉分割师根据猪的肉质结构、肉质形态、以及用途对猪肉进行粗分割，便于运输和食用。
              </div>
            </div>
            <img src="../assets/download/tz6.png" alt="" class="tupian-1" />
          </div>
          <div class="one">
            <img src="../assets/download/tz7.png" alt="" class="tupian-2" />
            <div class="teleft text-1">
              <div class="title">出库包装</div>
              <div class="text">
                出库前工作人员对猪肉进行无菌处理，真空包装，以保证猪肉纤维的活性，延长保质期。
              </div>
            </div>
          </div>
          <div class="one">
            <div class="teleft text-2">
              <div class="title">冷链运输</div>
              <div class="text">
                冷链运输是为保持新鲜食品及冷冻食品的品质，使其从生产、运输到收货的过程中，始终处于低温状态，提高了食品的保鲜能力。
              </div>
            </div>
            <img src="../assets/download/tz8.png" alt="" class="tupian-1" />
          </div>
          <div class="one">
            <img src="../assets/download/tz9.png" alt="" class="tupian-2" />
            <div class="teleft text-1">
              <div class="title">配送到家</div>
              <div class="text">
                到达目的地后，由配送员为您提供送货上门服务。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buttomcolor dynamic" v-if="aktiveindex == 5">
        <div class="dynamic-box frame" ref="scrollBox">
          <van-empty
            class="custom-image"
            image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
            description="近期暂无动态"
            v-if="incshow"
          />
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="fintext"
            :immediate-check="true"
            :offset="50"
            @load="onLoad"
          >
            <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
            <div
              class="dynamic-display"
              v-for="(item, index) in dynamiclist"
              :key="index"
            >
              <div class="dynamic-left-right" >
                <div class="left">
                  <img  v-if="item.image!=''" :src="item.image" alt="" />
                  <img v-if="item.image==''" src="../assets/queshi.jpg" alt="" />
                </div>
                <div class="right" @click="dynamicxq(item.id)">
                  <div class="title">{{ item.title }}</div>
                  <div class="dynamic-center">
                    {{ item.description }}
                  </div>
                  <div class="ckxq">查看详情></div>
                </div>
              </div>
            </div>
          </van-list>
        </div>
      </div>
      <div class="footer">
        <cooter></cooter>
      </div>
      <div class="but-jia"></div>
      <div class="but-xiazai" @click="clickBtn">
        立即下载
      </div>
      <van-popup v-model="show">
        <div class="bossBox">
          <p class="verify">手机号绑定验证</p>
          <div>
            <van-cell-group>
              <van-field
                v-model="phone"
                clearable
                :border="false"
                type="number"
                maxlength="11"
                placeholder="请填写手机号"
                left-icon="http://static.yhaocang.com/img/pages/pc/phone.png"
              />
            </van-cell-group>
          </div>
          <div class="yanzheng">
            <van-cell-group class="cell">
              <van-field
                v-model="code"
                :border="false"
                placeholder="验证码"
                left-icon="http://static.yhaocang.com/img/pages/pc/ma.png"
              />
            </van-cell-group>
            <div v-show="codeShow" class="song" @click="getCode">
              获取验证码
            </div>
            <div v-show="!codeShow" class="song">
              {{ yzmTitle }}
            </div>
          </div>
          <div class="sure" @click="isTrue">下载</div>
        </div>
      </van-popup>
      <p class="titile" v-show="titleShow">{{ msg }}</p>
      <div v-if="isweixin" class="isWeixinOpen">
        <img src="../assets/img/zao.png" alt="" />
      </div>
      <t-captcha-js src="https://ssl.captcha.qq.com/TCaptcha.js"></t-captcha-js>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-hls'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import { getDownUrl, sendSms, bindFriend, trendst ,pig_list,meal_list,butcher_config} from '../api/index'
import cooter from '../components/cooter.vue'
var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?1e1b99b44d8c3e2bf261f1b93d4429be";
  var s = document.getElementsByTagName("script")[0]; 
  s.parentNode.insertBefore(hm, s);
})();
export default {
  components: {
    't-captcha-js': {
      render(createElement) {
        return createElement('script', {
          attrs: { type: 'text/javascript', src: this.src }
        })
      },
      props: {
        src: { type: String, required: true }
      }
    },
    videoPlayer,
    Carousel3d,
    Slide,
    cooter,
  },
  data() {
    return {
      scroll: '0',
      scrollshow: false,
      header: '',
      navigation: '',
      num: '',
      imgindex: 1,
      lenght: 0,
      prevs: 1,
      swipeimg: [
        require('../assets/download/yumi.png'),
        require('../assets/download/gouqi.png'),
        require('../assets/download/taochan.png')
      ],
      aktiveindex: 1,
      playerOptions: {
        // autoplay: false,
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: 'https://video.hxnc.cc/hxnc/video/sucai/xc.mp4' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      },
      playerOptionsTwo: {
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: 'https://video.hxnc.cc/hxnc/video/sucai/dzhld.mp4' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      },
      playerOptionsthree: {
        muted: true, // 默认情况下将会消除任何音频。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            // type: 'application/x-mpegURL',
            type: 'video/mp4',
            src: 'https://video.hxnc.cc/hxnc/video/sucai/ystyz.mp4' // url地址
          }
        ],
        controlBar: {
          timeDivider: false, // 当前时间和持续时间的分隔符
          durationDisplay: false, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: false // 是否显示全屏按钮
        }
      },

      show: false,
      phone: '',
      msg: '', //提示
      code: '',
      yzmTitle: '',
      titleShow: false,
      codeShow: true, //图片验证
      user: '',
      isweixin: false,
      dialogShow: false, //提示
      index: 1,
      message: '',
      isios: false,
      isandriod: false,
      andriod: '',
      ios: '',
      info:false,
      dynamiclist: [],
      photos: [
        {
          url: require('../assets/download/dong1.gif')
        },
        {
          url: require('../assets/download/dong2.gif')
        },
        {
          url: require('../assets/download/dong3.gif')
        }
      ],
      list: [],
      loading: false,
      finished: false,
      page: 1,
      pageSize: 5,
      incshow: false,
      rand:'',
      piglist:'',
      driver:'',
      butcher:'',
      logistics:'',
      cope:'www.hxnc.cc',
      fintext: '没有更多了'
    }
  },
  created() {
    if (this.$route.query.typeindex) {
      this.aktiveindex = this.$route.query.typeindex
    }
    this.appSource()
    // this.trendlist()
  },
  mounted() {
    // console.log('你好')

    if (this.$route.query.type == 0) {
      console.log(123333)
      this.index = 0
    } else {
      this.index = 1
    }
    window.addEventListener('scroll', this.scrollDs, true)

    this.$nextTick(() => {
      // this.$refs.scrollBox.addEventListener('scroll', this.handleScroll, true)
      // this.$refs.homeUl.addEventListener('scrollcc', this.handleScroll, true)
    })
  },
  watch: {
    scroll(val) {
      this.header = this.$refs.header.offsetHeight // 头部高度
      // console.log(this.header, '动态获取高度')
      this.navigation = this.$refs.navigation.offsetHeight // 头部高度
      // console.log(this.navigation, '动态获取高度')
      // console.log(val, 'changdu')
      // if (this.scroll > 400) {
      //   this.scrollshow = true
      // } else if (this.scroll <= 400) {
      //   this.scrollshow = false
      // }
      this.num = this.header + this.navigation + 50
      if (this.scroll > this.num) {
        this.scrollshow = true
      } else if (this.scroll <= this.num) {
        this.scrollshow = false
      }
    },
    aktiveindex(val){
      console.log(val)
      if(val==2){
        this.pig_list()
      }else if(val == 3){
        this.meal_list()
      }else if(val == 4){
        this.butcher_config()
      }
    }
  },
  
  methods: {
    onLoad() {
      this.trendlist()
      // this.page++
    },
    handleScroll() {
      console.log(123)
    },
    dynamicxq(id) {
      this.$router.push({
        path: '/dynamicxq',
        query: { xqid: id }
      })
    },
    async pig_list(){
        let res = await pig_list()
        if(res.data.code == 200){
           this.piglist = res.data.data
        }else{
            this.$toast(res.data.message);
        }
        console.log(res,"数据")
      },
      async meal_list(){
        let res = await meal_list()
        if(res.data.code == 200){
           this.piglist = res.data.data
        }else{
            this.$toast(res.data.message);
        }
        console.log(res,"数据")
      },
      async butcher_config(){
        let res = await butcher_config()
        if(res.data.code == 200){
           this.driver = res.data.data.driver_listing_fee,
           this.butcher = res.data.data.butcher_price,
           this.logistics = res.data.data.logistics_cost
        }else{
            this.$toast(res.data.message);
        }
        console.log(res,"数据")
      },
    copy1(){
      this.$copyText(this.cope).then(
	      e=>{
          this.$toast("复制成功");
	      },
	      e=>{
	        console.log('复制失败：', e);
	      })
    },
    async trendlist() {
      this.loading = true
      let res = await trendst({
        page: this.page,
        pageSize: this.pageSize
      })
      if (res.data.code == 200) {
        if (this.page == 1) {
          this.dynamiclist = res.data.data.list
        } else {
          this.dynamiclist = [...this.dynamiclist, ...res.data.data.list]
        }
        if (res.data.data.list.length > 0) {
          console.log('进来没')
          this.page++
          this.loading = false
        } else {
          this.fintext = '没有更多了'
          this.finished = true
        }
      } else {
        this.fintext = '没有更多了'
        this.finished = true
      }
      if (this.dynamiclist.length == 0) {
        this.fintext = ''
        this.incshow = true
      }
      console.log(res, '数据')
    },
    scrollDs: function () {
      // 页面指定了DTD，即指定了DOCTYPE时，使用document.documentElement。
      // 页面没有DTD，即没指定DOCTYPE时，使用document.body。
      this.scroll =
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        window.pageYOffset
      // console.log(this.scroll)
    },
    async aktiveindexdata() {
      await (this.aktiveindex = 2)
      await (document.querySelector('.next').innerHTML = '')
      await (document.querySelector('.prev').innerHTML = '')
    },
    async Jumpindex(e) {
      await (this.aktiveindex = e)
      if (e == 2) {
        await (document.querySelector('.next').innerHTML = '')
        await (document.querySelector('.prev').innerHTML = '')
      }
      if (this.scroll > this.num) {
        // this.scroll = 0
        // document.documentElement.scrollTop = 0
      }
      console.log(this.scroll, '数值')
    },
    // 剪贴板
    dataProcessing(val) {
      // this.message = '#' + val + '#'
      this.message = val
    },
    // 剪贴板
    doCopy(val) {
      this.dataProcessing(val)
      this.$copyText(this.message).then(
        function (e) {
          console.log(e, 9999)
          console.log(e.text, 9999)
        },
        function (e) {
          console.log(e, 8888)
          console.log(e.text, 888888)
        }
      )
    },
    onChange(index) {
      console.log('当前 Swipe 索引：' + index)
      this.lenght = index
    },
    onChangeprev(e) {
      if (this.lenght > 0) {
        this.$refs.swiper.swipeTo(this.lenght - 1)
      } else if (this.lenght <= 0) {
        this.$refs.swiper.swipeTo(2)
      }
      // console.log(es, 9999)
    },
    onChangenaxt(e) {
      if (this.lenght >= 2) {
        this.$refs.swiper.swipeTo(0)
      } else if (this.lenght > -1) {
        this.$refs.swiper.swipeTo(this.lenght + 1)
      }
    },
    //
    async appSource() {
      let res = await getDownUrl({
        planform: this.$route.query.planform ? this.$route.query.planform : ''
      })
      if (res.data.code != 200) return
      this.andriod = res.data.data.andriod_url
      this.ios = res.data.data.ios_url
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (isiOS) {
        this.isios = true
      } else {
        this.isandriod = true
      }
    },
    isDouyinClient(){
    var ua = window.navigator.userAgent.toLowerCase();
     this.info = ua.indexOf('aweme') != -1

    },
    xiaoshi(){
      this.info = false
    },
    async clickBtn() {
      this.isDouyinClient()
      var ua = navigator.userAgent.toLowerCase()
      var isWeixin = ua.indexOf('micromessenger') != -1
      if (isWeixin) return (this.isweixin = true)

      if (this.$route.query.invite_code) {
        await this.doCopy(this.$route.query.invite_code)
      }
      const u = navigator.userAgent
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
      if (this.$route.query.rid != '' && this.$route.query.rid != undefined) {
        console.log(123)
        console.log(this.$route.query.rid, this.$route.query.store_id)

        if (isiOS) {
          // this.dialogShow = true // 若是ios 做操作
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (
        this.$route.query.store_id != '' &&
        this.$route.query.store_id != undefined &&
        this.$route.query.store_id != 1
      ) {
        if (isiOS) {
          // this.dialogShow = true // 若是ios 做操作
          this.show = true // 若是ios 做操作
        } else {
          this.show = true // 要不就是Android
        }
      } else if (this.$route.query.invite_code) {
        console.log(456)

        if (this.isios) {
          console.log(this.ios) //ios
          // window.open(this.ios)
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          // window.open(this.andriod)
          window.location.href = this.andriod
        }
      } else {
        // if (isiOS) {
        //   // this.dialogShow = true // 若是ios 做操作
        //   this.show = true // 若是ios 做操作
        // } else {
        //   this.show = true // 要不就是Android
        // }
        if (this.isios) {
          console.log(this.ios) //ios
          // window.open(this.ios)
          window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
        }
        if (this.isandriod) {
          console.log(this.andriod) //andriod
          // window.open(this.andriod)
          window.location.href = this.andriod
        }
      }
    },
    async isTrue() {
      console.log('确定')

      let data = {
        rid: this.$route.query.rid || '',
        uid: this.$route.query.uid,
        phone: this.phone,
        keyCode: this.code,
        store_id: this.$route.query.store_id
      }
      let res = await bindFriend(data)
      console.log(res, 'resres')
      if (res.data.code != 200) {
        this.msg = res.data.message
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      if (this.isios) {
        console.log(this.ios) //ios
        // window.open(this.ios)
        window.location.href = 'https://itunes.apple.com/cn/app/id1614216413'
      }
      if (this.isandriod) {
        console.log(this.andriod) //andriod
        // window.open(this.andriod)
        window.location.href = this.andriod
      }
      this.show = !this.show
    },
    getCode() {
      if (!this.phone) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      let reg = /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!reg.test(this.phone)) {
        this.msg = '请输入正确的手机号码'
        console.log(this.msg, 'this.msg')
        this.titleShow = true
        setTimeout(() => {
          this.titleShow = false
        }, 2000)
        return
      }
      this.showCodeImg()
    },
    showCodeImg() {
      // eslint-disable-next-line no-undef
      var captcha1 = new TencentCaptcha('2008794111', (coderes) => {
        console.log(coderes)
        const { randstr, ticket } = coderes
        sendSms({ phone: this.phone, type1: 1, randstr, ticket }).then(
          (res) => {
            console.log(res)
            if (res.data.code == 200) {
              let time = 60
              let timer = setInterval(() => {
                if (time == 0) {
                  clearInterval(timer)
                  this.codeShow = true
                } else {
                  this.codeShow = false
                  this.yzmTitle = time + '秒后重试'
                  time--
                }
              }, 1000)
              this.msg = '获取验证码成功'
              console.log(this.msg, 'this.msg')
              this.titleShow = true
              setTimeout(() => {
                this.titleShow = false
              }, 2000)
            } else {
              this.$toast(res.data.message)
            }
          }
        )
      })
      captcha1.show()
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .mask-box {
        position: fixed;
        min-height: 100vh;
        background-color: rgba(0,0,0,.5);
        opacity: 1;
        display: block;
        z-index: 999;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .master{
          background: #ffffff;
          padding: 20px;
          font-size: 32px;
          text-align: center;
          line-height: 50px;
        }
    }
  .box {
    width: 100vw;
    height: 100vh;
    .frame {
      border: 3.5px solid #58340c;
      box-sizing: border-box;
    }
    .header {
      width: 100vw;
      height: 480px;
      // background-image: url('../assets/download/header.png');
      // background-repeat: no-repeat;
      // background-size: 100%;
      img {
        width: 100%;
        height: 100%;
      }
      .logo {
        position: absolute;
        left: 28px;
        top: 39px;
        width: 228px;
        height: 80px;
      }
    }
    .fled {
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000000 !important;
    }
    .navigation {
      width: 100vw;
      height: 83px;
      background: #01ce69;
      padding: 0 47px;
      box-sizing: border-box;
      // display: flex;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
      }
      li {
        cursor: pointer;
        flex: 1;
        height: 100%;
        // background: #f1ff10;
        font-size: 20.8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .aktive {
        background: #f1ff10;
      }
    }
    .buttomcolor {
      background: #6bd09e;
    }
    .ppgsbuttom {
      padding: 43.75px 0 0 0;
      box-sizing: border-box;
      .ppgs-viope-box {
        width: 638px;
        height: 359px;
        // background: #000;
        margin: 0 auto;
        margin-bottom: 67px;

        /deep/ #myplayer {
          width: 100%;
          height: 100%;
        }
      }
      .pig-table {
        margin: 0 auto;
        width: 584px;
        height: 60px;
        background: #f1ff10;
        line-height: 60px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 25px;
        color: #035a5c;
        margin-bottom: 81.25px;
      }
      .fangxinchi {
        width: 100vw;
        height: 408px;
        text-align: center;
        margin-bottom: 89.58px;
        img {
          width: 679px;
          height: 408px;
        }
      }
      .fangxinchi-box {
        margin: 0 auto;
        width: 679px;
        height: 440px;
        text-align: center;
        margin-bottom: 89.58px;
        background-image: url('../assets/download/yangzufx.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 110px 60px 0 48px;
        box-sizing: border-box;
        .duizhong {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 110px;
            height: 222px;
            // border: 3px solid #035a5c;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            text-align: left;
            width: 418px;
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #6a4a20;
            line-height: 38px;
          }
        }
      }
      .linian-box {
        width: 100vw;
        height: 1061.8px;
        text-align: center;
        margin-bottom: 126px;
        img {
          width: 717.36px;
          height: 1061.8px;
        }
      }
      .ppgs-buttom-box {
        // width: 717.36px;
        width: 690px;
        height: 1061.8px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 126px;
        padding-top: 18.75px;
        box-sizing: border-box;
        background: #ffffff;
        .title-lit {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 2px;
        }
        /deep/ .title {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 24px;
        }
        .text {
          width: 248px;
          // height: 221px;
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 58px;
          .img-box-left {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-left: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-box-right {
            text-align: left;
            padding-right: 10px;
            box-sizing: border-box;
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 88px;

          .img-box-left {
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .text-box-right {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-right: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dianji-img-box {
        width: 100vw;
        height: 457px;
        text-align: center;
        margin-bottom: 16px;
        img {
          width: 645px;
          height: 457px;
        }
      }
      .dianji-img-six {
        width: 645px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 120px;
        padding-bottom: 50px;
        // box-sizing: border-box;
        img {
          width: 96px;
          height: 70px;
        }
        .aktive {
          background: #ffffff;
          border: 4px solid #ffffff;
        }
      }
      .ppgsfooter {
        width: 100vw;
        height: 333.33px;
        background: #2e2e2e;
        // text-align: center;
        // line-height: 333px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          width: 660px;
          font-size: 26.6px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff !important;
          margin-bottom: 10px;
          // margin-left: 73px;
        }
      }
    }
    .spin_img {
      // width: 100%;
      // height: 100%;
      // width: 200px;
      // height: 300px;
      width: 361px;
      height: 486px;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slide {
      width: 361px !important;
      height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slider {
      width: 361px !important;
      height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .next {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/next.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    ::v-deep .prev {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/prev.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    .lybuttom {
      padding: 0 0 76px 0;
      box-sizing: border-box;

      .linyang{
        width: 92vw;
        height: 500px;
        margin-left: 4vw;
        margin-bottom: 30px;
        background: url("../assets/linyang.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: 100%;
      }
      .canva{
        display: flex;
        line-height: 60px;
      }

      .swipc-box-3d {
        width: 692px;
        margin: 0 auto;
        margin-bottom: 64px;
      }
      .lingyang-m-b {
        margin-bottom: 74px !important;
      }
      .lingyang {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 58px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }

      .ly-list {
        width: 100vw;
        height: 850.69px;
        text-align: center;
        margin-bottom: 65px;
        img {
          width: 692px;
          height: 850.69px;
        }
      }
      .ly-list-box {
        // width: 692px;
        // height: 850.69px;
        width: 685px;
        height: 843.69px;
        text-align: center;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 42px 23px 0 18px;
        box-sizing: border-box;
        margin-bottom: 51px;
        .title {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-bottom: 61px;
          span {
            font-size: 33.33px;
            font-family: HYHanHeiW;
            font-weight: 700;
            color: #37b96f;
            margin-right: 18px;
          }
          img {
            width: 28.47px;
            height: 50.69px;
          }
        }
        .img-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .tu-1 {
            width: 400px;
            height: 227px;
          }
          .tu-2 {
            width: 229px;
            height: 227px;
          }
          .tu-3 {
            width: 265px;
            height: 149px;
          }
          .tu-4 {
            width: 175px;
            height: 149px;
          }
          .tu-5 {
            width: 178px;
            height: 149px;
          }
        }
        .text-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            // width: 312px;
            // height: 209px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #644110;
            text-align: left;
          }
          img {
            width: 275px;
            height: 206.9px;
            margin-left: 12px;
          }
        }
      }
      .lc-list {
        // width: 100vw;
        height: 325px;
        // padding: 0 21px;
        width: 699px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width: 217.36px;
          height: 325px;
        }
      }
      .lc-m-b {
        margin-bottom: 64px;
      }
      .ly-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/ly-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .wybuttom {
      padding: 40px 0 72px 0;
      box-sizing: border-box;

      .weiyang{
        width: 92vw;
        height: 700px;
        margin-left: 4vw;
        margin-bottom: 30px;
        background: url("../assets/weiyang.png");
        background-repeat:repeat;
        background-size:100% 100%;  
      }
      .canvas{
        height: 540px;
        overflow:auto;
        margin-top: 10px;
      }
      .wy-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/wy-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        margin-bottom: 52px;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
      .wy-swipe-box {
        text-align: center;
        margin: 0 auto;
        width: 682px;
        height: 582px;
        background-image: url('../assets/download/wy-swpile.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        // margin-bottom: 91px;
        padding: 54px 101px 0 81.25px;
        margin-bottom: 112px;
        box-sizing: border-box;
        position: relative;
        .text-box {
          width: 492px;
          min-height: 146px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          text-align: left;
          margin-bottom: 23px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .zuo {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          left: 40px;
          top: 336px;
          // left: -30px;
          // top: 0px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .you {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          right: 52px;
          top: 336px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .custom-indicator {
          margin: 0 auto;
          width: 153px;
          height: 12px;
          background: #cdf0d7;
          border-radius: 8px;
          margin-top: 20px;
          display: flex;
          .lu {
            // flex: 1;
            width: 51px;
            height: 12px;
            background: #00b534;
            border-radius: 8px;
          }
          .bai {
            width: 51px;
            height: 12px;
            border-radius: 8px;
          }
        }
        .swipe-box {
          // position: relative;

          width: 500px;
          height: 281.25px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tuandui {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 68.75px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .list-m-b {
        margin-bottom: 93px !important;
      }
      .tuandui-list {
        width: 100vw;
        height: 315px;
        text-align: center;
        margin-bottom: 34px;
        img {
          width: 676px;
          height: 315px;
        }
      }
      .tuandui-list-box {
        // width: 676px;
        width: 669px;
        // height: 315px;
        height: 308px;

        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px 0 14px;
        // padding: 0 16px 0 14px;
        box-sizing: border-box;
        .title {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 37.5px;
        }
        .title-1 {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 13.8px;
        }
        .text {
          // width: 207px;
          // height: 76px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          margin-bottom: 10px;
        }
        .text-1 {
          width: 234px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        img {
          width: 381px;
          height: 264px;
        }
        .right {
          width: 234px;
          text-align: left;
        }
      }
      .hougu-list {
        width: 100vw;
        height: 740px;
        text-align: center;
        img {
          width: 713px;
          height: 740px;
        }
      }
      .hougu-list-box {
        // width: 713px;
        // height: 740px;
        width: 706px;
        height: 733px;
        margin: 0 auto;
        background-color: #ffffff;
        padding-top: 22px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 400;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        .weiya-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 107px;

          .img-1 {
            width: 445px;
            height: 252px;
            padding-left: 15px;
            box-sizing: border-box;
          }
          .img-2 {
            width: 454px;
            height: 272px;
            padding-right: 14px;
            box-sizing: border-box;
          }
          .left {
            width: 220px;
            text-align: left;
            padding-left: 28px;
            box-sizing: border-box;
          }
          .right {
            width: 220px;
            text-align: left;
            padding-right: 22px;
            box-sizing: border-box;
          }
        }
      }
    }
    .tzbuttom {
      background: #6bd09e;
      padding-top: 26px;
      padding-bottom: 160px;

      .tuzai{
        width: 96vw;
        height: 420px;
        margin-left: 2vw;
        margin-bottom: 30px;
        background: url("../assets/tuzai.png");
        background-repeat:repeat;
        background-size:100% 100%;  
      }
      .slaughter-top {
        width: 100vw;
        height: 900px;
        text-align: center;
        margin-bottom: 57px;
        img {
          width: 713px;
          height: 900px;
        }
      }
      .slaughter-top-box {
        // width: 713px;
        // height: 920px;
        width: 706px;
        height: 913px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        padding: 39px 20px 0 15px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 27px;
        }
        .text {
          // width: 646px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 36px;
        }
        .fd-img {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 21px;

          img {
            width: 332px;
            height: 252px;
          }
        }
        .tecenter {
          width: 666.66px;
          height: 377px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .slaughter-title {
        width: 100vw;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 57px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .slaughter-buttom {
        width: 100vw;
        height: 3245px;
        text-align: center;

        // padding: 0 21px;
        // box-sizing: border-box;
        margin-bottom: 57px;
        img {
          width: 710px;
          height: 3245px;
        }
      }
      .slaughter-buttom-box {
        // width: 710px;
        // min-height: 3245px;
        width: 707px;
        min-height: 3238px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        // padding-top: 25px;
        padding: 25px 13px 31px 13px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .title-mb {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 12px;
        }
        .text {
          // width: 230px;
          // width: 220px;
          width: 200px;
          // height: 187.5px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        // .text-1 {
        //   padding-right: 25px;
        //   box-sizing: border-box;
        // }
        // .text-2 {
        //   padding-left: 20px;
        //   box-sizing: border-box;
        // }
        .tupian-1 {
          width: 445px;
          height: 252px;
          // padding-left: 13.8px;
          // box-sizing: border-box;
        }
        .tupian-2 {
          width: 454px;
          height: 270px;
          // padding-right: 15px;
          // box-sizing: border-box;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 111px;
          .teleft {
            text-align: left;
          }
        }
      }
    }
    .dynamic {
      background: #6bd09e;
      padding-top: 45px;
      padding-bottom: 112px;
      .dynamic-box {
        width: 694px;
        height: 902px;
        // height: 910px;
        margin: 0 auto;
        background: #ffffff;
        padding: 20px 34px 10px 20px;
        box-sizing: border-box;
        overflow-y: scroll;
        position: relative;
        ::v-deep .van-empty {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
        }
        ::v-deep .custom-image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
        }
        .dynamic-left-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 34px;
          .left {
            img {
              width: 222px;
              height: 138px;
            }
          }
          .right {
            width: 380px;
            .title {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }
            .dynamic-center {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 8px 0 8px 0;
            }
            .ckxq {
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              text-align: right;
            }
          }
        }
      }
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      width: 500px;
      height: 281.25px;
      // width: 100%;
      // height: 100%;
      text-align: center;
      background-color: #39a9ed;
    }
    .isWeixinOpen {
      width: 100vw;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .but-jia {
      width: 100vw;
      height: 91px;
    }
    .but-xiazai {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      height: 91px;
      background: #f1ff10;
      border-top: 3px solid #32b76a;

      font-size: 41px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #048145;
      text-align: center;
      line-height: 91px;
    }
    .logoImg {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 2.2rem;
        object-fit: contain;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6.7rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .bossBox {
      width: 8rem;
      height: 6.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .verify {
        font-size: 0.53rem;
        color: #333;
        font-weight: 400;
        padding: 0.8rem 0 0.25rem 0;
      }
      .yanzheng {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .song {
          width: 2.76rem;
          height: 1.15rem;
          background: #43d227;
          border-radius: 0.1rem;
          margin-top: 0.2rem;
          font-size: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fefefe;
          font-weight: 400;
        }
      }
      .sure {
        width: 4.6rem;
        height: 1.1rem;
        background: #43d227;
        font-size: 0.45rem;
        color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        margin-top: 0.4rem;
        border-radius: 2rem;
      }
    }
    .van-cell-group {
      margin-top: 0.2rem;
      width: 7rem;
      .van-cell {
        height: 1.2rem;
        display: flex;
        align-items: center;
        background: #f2f2f2;
        border-radius: 0.15rem;
        overflow: hidden;
      }
      /deep/ .van-icon {
        font-size: 0.8rem;
        margin: 0 0.3rem 0 0.34rem;
      }

      /deep/ .van-field__body {
        font-size: 0.41rem;
      }
    }
    .cell {
      width: 4rem;
      /deep/ .van-icon {
        font-size: 0.65rem;
        margin: 0 0.3rem 0 0.41rem;
      }
    }
    .titile {
      font-size: 0.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#000, 0.4);
      padding: 0.2rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      z-index: 999999;
    }
    .isWeixinOpen {
      width: 100vw;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .van-dialog {
      width: 6rem;
      height: 3.5rem;
      /deep/ .van-hairline--top {
        height: 0.9rem;
        margin-top: 0.6rem;
      }
      /deep/ .van-button {
        height: 100%;
      }
      .titleInfo {
        margin: 0.4rem 0 0.1rem;
      }
      P {
        width: 100%;
        text-align: center;
        font-size: 0.34rem;
        margin-top: 0.1rem;
      }
    }
  }
}
@media screen and(min-width: 901px) and(max-width: 3000px) {
  // .daxxiao {
  //   // width: 100%;
  //   // height: 100%;
  //   width: 100vw;
  //   height: 100vh;
  // }
  .box {
    width: 1000px;
    // height: 100vh;
    margin: 0 auto;
    .frame {
      border: 3.5px solid #58340c;
      box-sizing: border-box;
    }
    .header {
      width: 1000px;
      height: 580px;
      // height: 600px;
      // background-image: url('../assets/download/header.png');
      // background-repeat: no-repeat;
      // background-size: 100%;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        // position: relative;
      }
      .logo {
        position: absolute;
        left: 28px;
        top: 39px;
        width: 228px;
        height: 80px;
      }
    }
    .fled {
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1000000 !important;
      width: 1000px;
    }
    .navigation {
      // width: 100vw;
      width: 1000px;
      height: 83px;
      background: #01ce69;
      padding: 0 47px;
      box-sizing: border-box;
      // display: flex;
      ul {
        width: 100%;
        height: 100%;
        display: flex;
      }
      li {
        cursor: pointer;
        flex: 1;
        height: 100%;
        // background: #f1ff10;
        font-size: 20.8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .aktive {
        background: #f1ff10;
      }
    }
    .buttomcolor {
      background: #6bd09e;
    }
    .ppgsbuttom {
      padding: 43.75px 0 0 0;
      box-sizing: border-box;
      .ppgs-viope-box {
        width: 638px;
        height: 359px;
        // background: #000;
        margin: 0 auto;
        margin-bottom: 67px;

        /deep/ #myplayer {
          width: 100%;
          height: 100%;
        }
      }
      .pig-table {
        margin: 0 auto;
        width: 584px;
        height: 60px;
        background: #f1ff10;
        line-height: 60px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 500;
        font-size: 25px;
        color: #035a5c;
        margin-bottom: 81.25px;
      }
      // .fangxinchi {
      //   width: 100vw;
      //   height: 408px;
      //   text-align: center;
      //   margin-bottom: 89.58px;
      //   img {
      //     width: 679px;
      //     height: 408px;
      //   }
      // }
      .fangxinchi-box {
        margin: 0 auto;
        width: 679px;
        height: 408px;
        text-align: center;
        margin-bottom: 89.58px;
        background-image: url('../assets/download/yangzufx.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 110px 60px 0 48px;
        box-sizing: border-box;
        .duizhong {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .left {
            width: 110px;
            height: 222px;
            // border: 3px solid #035a5c;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            text-align: left;
            width: 418px;
            font-size: 21px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #6a4a20;
            line-height: 38px;
          }
        }
      }
      .linian-box {
        // width: 100vw;
        width: 1000px;
        height: 1061.8px;
        text-align: center;
        margin-bottom: 126px;
        img {
          width: 717.36px;
          height: 1061.8px;
        }
      }
      .ppgs-buttom-box {
        // width: 717.36px;
        width: 690px;
        height: 1061.8px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 126px;
        padding-top: 18.75px;
        box-sizing: border-box;
        background: #ffffff;
        .title-lit {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 2px;
        }
        /deep/ .title {
          width: 248px;
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          // text-align: left !important;
          margin-bottom: 24px;
        }
        .text {
          width: 248px;
          // height: 221px;
          font-size: 21px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 58px;
          .img-box-left {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-left: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text-box-right {
            text-align: left;
            padding-right: 10px;
            box-sizing: border-box;
          }
        }
        .two {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 88px;

          .img-box-left {
            text-align: left;
            padding-left: 10px;
            box-sizing: border-box;
          }
          .text-box-right {
            // width: 425px;
            width: 420px;
            height: 281.25px;
            // border: 5.5px solid #ffffff;
            // background-color: #00b534;
            margin-right: -10px;
            box-sizing: border-box;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .dianji-img-box {
        // width: 100vw;
        width: 1000px;
        height: 457px;
        text-align: center;
        margin-bottom: 16px;
        img {
          width: 645px;
          height: 457px;
        }
      }
      .dianji-img-six {
        width: 645px;
        height: 70px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        // margin-bottom: 120px;
        padding-bottom: 50px;
        // box-sizing: border-box;
        img {
          width: 96px;
          height: 70px;
        }
        .aktive {
          background: #ffffff;
          border: 4px solid #ffffff;
        }
      }
      .ppgsfooter {
        // width: 100vw;
        width: 1000px;
        height: 333.33px;
        background: #2e2e2e;
        // text-align: center;
        // line-height: 333px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div {
          width: 660px;
          font-size: 26.6px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #ffffff !important;
          margin-bottom: 10px;
          // margin-left: 73px;
        }
      }
    }
    .spin_img {
      // width: 100%;
      // height: 100%;
      // width: 200px;
      // height: 300px;
      width: 361px;
      min-height: 486px;
      // height: 586px;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-container {
      min-height: 486px !important;
    }
    ::v-deep .carousel-3d-slide {
      width: 361px !important;
      min-height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .carousel-3d-slider {
      width: 361px !important;
      min-height: 486px !important;
      border-radius: 40px;
    }
    ::v-deep .next {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/next.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    ::v-deep .prev {
      width: 32px !important;
      height: 41px !important;
      background-image: url('../assets/download/prev.png') !important;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 100%;
      // position: absolute;
      // top: 0;
      // right: -30px;
      // z-index: 9999999 !important;
    }
    .lybuttom {
      padding: 79px 0 76px 0;
      box-sizing: border-box;
      .swipc-box-3d {
        width: 692px;
        margin: 0 auto;
        margin-bottom: 64px;
        // min-height: 500px;
      }
      .lingyang-m-b {
        margin-bottom: 74px !important;
      }
      .lingyang {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 58px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }

      .ly-list {
        // width: 100vw;
        width: 1000px;
        height: 850.69px;
        text-align: center;
        margin-bottom: 65px;
        img {
          width: 692px;
          height: 850.69px;
        }
      }
      .ly-list-box {
        // width: 692px;
        // height: 850.69px;
        width: 685px;
        height: 843.69px;
        text-align: center;
        margin: 0 auto;
        background-color: #ffffff;
        padding: 42px 23px 0 18px;
        box-sizing: border-box;
        margin-bottom: 51px;
        .title {
          display: flex;
          align-content: center;
          justify-content: center;
          margin-bottom: 61px;
          span {
            font-size: 33.33px;
            font-family: HYHanHeiW;
            font-weight: 700;
            color: #37b96f;
            margin-right: 18px;
          }
          img {
            width: 28.47px;
            height: 50.69px;
          }
        }
        .img-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 24px;
          .tu-1 {
            width: 400px;
            height: 227px;
          }
          .tu-2 {
            width: 229px;
            height: 227px;
          }
          .tu-3 {
            width: 265px;
            height: 149px;
          }
          .tu-4 {
            width: 175px;
            height: 149px;
          }
          .tu-5 {
            width: 178px;
            height: 149px;
          }
        }
        .text-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          span {
            // width: 312px;
            // height: 209px;
            font-size: 16px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #644110;
            text-align: left;
          }
          img {
            width: 275px;
            height: 206.9px;
            margin-left: 12px;
          }
        }
      }
      .lc-list {
        // width: 100vw;
        height: 325px;
        // padding: 0 21px;
        width: 699px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        img {
          width: 217.36px;
          height: 325px;
        }
      }
      .lc-m-b {
        margin-bottom: 64px;
      }
      .ly-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/ly-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .wybuttom {
      padding: 40px 0 72px 0;
      box-sizing: border-box;
      .wy-viope-box {
        text-align: center;
        margin: 0 auto;
        width: 679px;
        height: 519px;
        background-image: url('../assets/download/wy-viope.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        padding: 118.75px 54px 64px 37.77px;
        box-sizing: border-box;
        margin-bottom: 52px;
        .videos {
          width: 576px;
          height: 336px;
          // margin-top: 4rem;
          // background: #000;
          /deep/ #myplayer {
            width: 100%;
            height: 100%;
          }
        }
      }
      .wy-swipe-box {
        text-align: center;
        margin: 0 auto;
        width: 682px;
        height: 582px;
        background-image: url('../assets/download/wy-swpile.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100%;
        // margin-bottom: 91px;
        padding: 54px 101px 0 81.25px;
        margin-bottom: 112px;
        box-sizing: border-box;
        position: relative;
        .text-box {
          width: 492px;
          min-height: 146px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          text-align: left;
          margin-bottom: 23px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          line-clamp: 5;
          -webkit-box-orient: vertical;
        }
        .zuo {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          left: 40px;
          top: 336px;
          // left: -30px;
          // top: 0px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .you {
          width: 21px;
          height: 34px;
          // background: #035a5c;
          border-radius: 6px;
          position: absolute;
          right: 52px;
          top: 336px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .custom-indicator {
          margin: 0 auto;
          width: 153px;
          height: 12px;
          background: #cdf0d7;
          border-radius: 8px;
          margin-top: 20px;
          display: flex;
          .lu {
            // flex: 1;
            width: 51px;
            height: 12px;
            background: #00b534;
            border-radius: 8px;
          }
          .bai {
            width: 51px;
            height: 12px;
            border-radius: 8px;
          }
        }
        .swipe-box {
          // position: relative;

          width: 500px;
          height: 281.25px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .tuandui {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 68.75px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .list-m-b {
        margin-bottom: 93px !important;
      }
      .tuandui-list {
        // width: 100vw;
        width: 1000px;
        height: 315px;
        text-align: center;
        margin-bottom: 34px;
        img {
          width: 676px;
          height: 315px;
        }
      }
      .tuandui-list-box {
        // width: 676px;
        width: 669px;
        // height: 315px;
        height: 308px;

        text-align: center;
        margin: 0 auto;
        margin-bottom: 40px;
        background-color: #ffffff;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 21px 0 14px;
        // padding: 0 16px 0 14px;
        box-sizing: border-box;
        .title {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 37.5px;
        }
        .title-1 {
          font-size: 25px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #37b96f;
          margin-bottom: 13.8px;
        }
        .text {
          // width: 207px;
          // height: 76px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
          margin-bottom: 10px;
        }
        .text-1 {
          width: 234px;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #644110;
        }
        img {
          width: 381px;
          height: 264px;
        }
        .right {
          width: 234px;
          text-align: left;
        }
      }
      .hougu-list {
        // width: 100vw;
        width: 1000px;
        height: 740px;
        text-align: center;
        img {
          width: 713px;
          height: 740px;
        }
      }
      .hougu-list-box {
        // width: 713px;
        // height: 740px;
        width: 706px;
        height: 733px;
        margin: 0 auto;
        background-color: #ffffff;
        padding-top: 22px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 400;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .text {
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        .weiya-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 107px;

          .img-1 {
            width: 445px;
            height: 252px;
            padding-left: 15px;
            box-sizing: border-box;
          }
          .img-2 {
            width: 454px;
            height: 272px;
            padding-right: 14px;
            box-sizing: border-box;
          }
          .left {
            width: 220px;
            text-align: left;
            padding-left: 28px;
            box-sizing: border-box;
          }
          .right {
            width: 220px;
            text-align: left;
            padding-right: 22px;
            box-sizing: border-box;
          }
        }
      }
    }
    .tzbuttom {
      background: #6bd09e;
      padding-top: 86px;
      padding-bottom: 160px;
      .slaughter-top {
        // width: 100vw;
        width: 1000px;
        height: 900px;
        text-align: center;
        margin-bottom: 57px;
        img {
          width: 713px;
          height: 900px;
        }
      }
      .slaughter-top-box {
        // width: 713px;
        // height: 920px;
        width: 706px;
        height: 913px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        padding: 39px 20px 0 15px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 27px;
        }
        .text {
          // width: 646px;
          font-size: 20px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 36px;
        }
        .fd-img {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 21px;

          img {
            width: 332px;
            height: 252px;
          }
        }
        .tecenter {
          width: 666.66px;
          height: 377px;
          margin: 0 auto;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .slaughter-title {
        // width: 100vw;
        width: 1000px;
        height: 78px;
        box-sizing: border-box;
        margin-bottom: 57px;
        text-align: center;
        line-height: 41px;
        img {
          width: 659px;
          height: 77.77px;
        }
      }
      .slaughter-buttom {
        // width: 100vw;
        width: 1000px;
        height: 3245px;
        text-align: center;

        // padding: 0 21px;
        // box-sizing: border-box;
        margin-bottom: 57px;
        img {
          width: 710px;
          height: 3245px;
        }
      }
      .slaughter-buttom-box {
        // width: 710px;
        // min-height: 3245px;
        width: 707px;
        min-height: 3238px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 57px;
        background: #ffffff;
        // padding-top: 25px;
        padding: 25px 13px 31px 13px;
        box-sizing: border-box;
        .title {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 29px;
        }
        .title-mb {
          font-size: 33.33px;
          font-family: HYHanHeiW;
          font-weight: 700;
          color: #37b96f;
          margin-bottom: 12px;
        }
        .text {
          // width: 230px;
          // width: 220px;
          width: 200px;
          // height: 187.5px;
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 500;
          color: #644110;
        }
        // .text-1 {
        //   padding-right: 25px;
        //   box-sizing: border-box;
        // }
        // .text-2 {
        //   padding-left: 20px;
        //   box-sizing: border-box;
        // }
        .tupian-1 {
          width: 445px;
          height: 252px;
          // padding-left: 13.8px;
          // box-sizing: border-box;
        }
        .tupian-2 {
          width: 454px;
          height: 270px;
          // padding-right: 15px;
          // box-sizing: border-box;
        }
        .one {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 111px;
          .teleft {
            text-align: left;
          }
        }
      }
    }
    // .dynamic-box {
    //   -ms-overflow-style: none;
    //   overflow: -moz-scrollbars-none;
    // }

    // ::-webkit-scrollbar {
    //   display: none;
    // }
    .dynamic {
      background: #6bd09e;
      padding-top: 45px;
      padding-bottom: 112px;
      // padding-right: 17px;
      box-sizing: border-box;
      // overflow: hidden;
      .dynamic-box::-webkit-scrollbar {
        display: none;
      }
      .dynamic-box {
        width: 694px;
        height: 910px;
        margin: 0 auto;
        background: #ffffff;
        padding: 20px 34px 10px 20px;
        box-sizing: border-box;
        // overflow: hidden;
        // overflow-y: scroll;
        overflow-y: auto;
        position: relative;
        // .dynamic-display {
        //   width: 711px;
        //   height: 902px;
        //   margin: 0 auto;
        //   background: #ffffff;
        //   padding: 20px 34px 10px 20px;
        //   box-sizing: border-box;
        //   overflow-y: scroll;
        // }
        ::v-deep .van-empty {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
        }
        ::v-deep .custom-image {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -60%);
        }
        .dynamic-left-right {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 34px;
          .left {
            img {
              width: 222px;
              height: 138px;
            }
          }
          .right {
            width: 380px;
            .title {
              font-size: 20px;
              font-family: Source Han Sans CN;
              font-weight: bold;
              color: #333333;
            }
            .dynamic-center {
              font-size: 16px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              line-clamp: 3;
              -webkit-box-orient: vertical;
              margin: 8px 0 8px 0;
            }
            .ckxq {
              cursor: pointer;
              font-size: 14px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              text-align: right;
            }
          }
        }
      }
    }
    .my-swipe .van-swipe-item {
      color: #fff;
      font-size: 20px;
      // line-height: 150px;
      width: 500px;
      height: 281.25px;
      // width: 100%;
      // height: 100%;
      text-align: center;
      background-color: #39a9ed;
    }
    .isWeixinOpen {
      // width: 100vw;
      width: 1000px;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .but-jia {
      // width: 100vw;
      width: 1000px;
      height: 91px;
    }
    .but-xiazai {
      position: fixed;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      // width: 100vw;
      width: 1000px;
      height: 91px;
      background: #f1ff10;
      border-top: 3px solid #32b76a;

      font-size: 41px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #048145;
      text-align: center;
      line-height: 91px;
    }
    .logoImg {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      img {
        width: 2.2rem;
        object-fit: contain;
        margin-right: 0.2rem;
        margin-top: 0.2rem;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 4rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .btns {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 6.7rem;
      img {
        width: 4rem;
        height: auto;
      }
    }
    .bossBox {
      width: 8rem;
      height: 6.5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin: 0;
        padding: 0;
      }
      .verify {
        font-size: 0.53rem;
        color: #333;
        font-weight: 400;
        padding: 0.8rem 0 0.25rem 0;
      }
      .yanzheng {
        width: 7rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .song {
          width: 2.76rem;
          height: 1.15rem;
          background: #43d227;
          border-radius: 0.1rem;
          margin-top: 0.2rem;
          font-size: 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fefefe;
          font-weight: 400;
        }
      }
      .sure {
        width: 4.6rem;
        height: 1.1rem;
        background: #43d227;
        font-size: 0.45rem;
        color: #fefefe;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        margin-top: 0.4rem;
        border-radius: 2rem;
      }
    }
    .van-cell-group {
      margin-top: 0.2rem;
      width: 7rem;
      .van-cell {
        height: 1.2rem;
        display: flex;
        align-items: center;
        background: #f2f2f2;
        border-radius: 0.15rem;
        overflow: hidden;
      }
      /deep/ .van-icon {
        font-size: 0.8rem;
        margin: 0 0.3rem 0 0.34rem;
      }

      /deep/ .van-field__body {
        font-size: 0.41rem;
      }
    }
    .cell {
      width: 4rem;
      /deep/ .van-icon {
        font-size: 0.65rem;
        margin: 0 0.3rem 0 0.41rem;
      }
    }
    .titile {
      font-size: 0.4rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgba(#000, 0.4);
      padding: 0.2rem 0.5rem;
      color: #fff;
      border-radius: 0.2rem;
      z-index: 999999;
    }
    .isWeixinOpen {
      // width: 100vw;
      width: 1000px;
      min-height: 100vh;
      position: fixed;
      background: rgba(#000, 0.4);
      top: 0;
      display: flex;
      justify-content: flex-end;
      img {
        width: 7rem;
        height: 7rem;
        margin-right: 0.2rem;
      }
    }
    .van-dialog {
      width: 6rem;
      height: 3.5rem;
      /deep/ .van-hairline--top {
        height: 0.9rem;
        margin-top: 0.6rem;
      }
      /deep/ .van-button {
        height: 100%;
      }
      .titleInfo {
        margin: 0.4rem 0 0.1rem;
      }
      P {
        width: 100%;
        text-align: center;
        font-size: 0.34rem;
        margin-top: 0.1rem;
      }
    }
  }
}
</style>
